<template>
  <div>
    <v-layout wrap justify-center>
      <v-flex xs12 sm12 md12 lg12 xl12 pt-4 pt-xl-12 px-2>
        <v-layout wrap justify-center>
          <v-flex xs12 sm10 pt-16 pb-2 text-left>
            <v-card tile flat>
              <v-layout wrap justify-start>
                <v-flex xs12 sm6 md6 lg6 xl6 text-left>
                  <span
                    style="
                      font-family: poppinsmedium;
                      font-size: 24px;
                      color: #000000;
                    "
                  >
                    Account Management
                  </span>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center>
          <v-flex xs12 sm10 pb-10>
            <v-layout wrap justify-center>
              <v-flex xs12 hidden-md-and-up>
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <span
                        style="
                          font-family: poppinsmedium;
                          font-size: 18px;
                          color: #545353;
                        "
                        >{{ $route.name }}</span
                      >
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-card class="mx-auto" max-width="500" tile flat>
                        <v-list>
                          <template v-for="(item, i) in navItems">
                            <v-list-item :key="item.name" link :to="item.route">
                              <v-list-item-content>
                                <v-list-item-title>
                                  <v-layout wrap justify-center py-4>
                                    <v-flex xs12 text-uppercase>
                                      <span
                                        style="
                                          font-family: poppinslight;
                                          font-size: 18px;
                                          color: #545353;
                                          letter-spacing: 1px;
                                        "
                                        >{{ item.name }}</span
                                      >
                                    </v-flex>
                                  </v-layout>
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider :key="i"></v-divider>
                          </template>
                        </v-list>
                      </v-card>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-flex>
              <!-- Desktop Nav -->
              <v-flex xs2 sm2 md3 lg3 xl2 text-left hidden-sm-and-down>
                <v-layout wrap justify-center>
                  <v-flex xs12 sm12>
                    <v-card color="#F5F5F5" class="mx-auto" max-width="500">
                      <v-list color="#F5F5F5" class="itempad">
                        <template v-for="(item, i) in navItems">
                          <v-list-item :key="item.name">
                            <v-list-item-title>
                              <router-link :to="item.route">
                                <v-layout wrap py-4 justify-center>
                                  <v-flex xs12 text-uppercase>
                                    <span
                                      :style="
                                        ($route.path == item.route || $route.path == '/Profile/orderDetails' && item.route== '/Profile/Orders' )
                                          ? {
                                              'font-family': 'poppinssemibold',
                                              color: '#30B868',
                                            }
                                          : {
                                              'font-family': 'poppinslight',
                                              color: '#1C1C1C',
                                            }
                                      "
                                      style="
                                        font-size: 18px;
                                        letter-spacing: 1px;
                                      "
                                      >{{ item.name }}</span
                                    >
                                  </v-flex>
                                </v-layout>
                              </router-link>
                            </v-list-item-title>
                          </v-list-item>
                          <v-divider :key="i"></v-divider>
                        </template>
                        <v-layout wrap pb-2 pt-2 justify-center>
                          <v-flex
                            xs12
                            pa-2
                            px-4
                            text-uppercase
                            @click="appLogout"
                            style="cursor: pointer;"
                          >
                            <span
                              style="
                                font-size: 18px;
                                letter-spacing: 1px;
                                font-family: poppinslight;
                                color: #1c1c1c;
                              "
                              >LOG OUT</span
                            >
                          </v-flex>
                          <!-- <v-flex xs12>
                            <v-divider></v-divider>
                          </v-flex> -->
                        </v-layout>
                      </v-list>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 sm12 md9 lg9 xl10>
                <v-layout wrap justify-start>
                  <!-- <v-divider vertical></v-divider> -->
                  <v-flex xs12 sm12 md12>
                    <router-view></router-view>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
//   import EditPicture from "./editPicture";
// import axios from "axios";
export default {
  components: {
    //   EditPicture,
  },
  data() {
    return {
      changePicDialog: false,
      // model: 0,
      dialogCoins: false,
      navItems: [
        { name: "Orders", route: "/Profile/Orders"},
        { name: "Addresses", route: "/Profile/Addresses" },
        { name: "Profile", route: "/Profile" },
        { name: "Cancellation Policy ", route: "/Profile/cancellationPolicy" },
        { name: "TERMS & CONDITIONS", route: "/Profile/termsAndConditions" },
        { name: "PRIVACY POLICY", route: "/Profile/privacyPolicy" },
        // { name: "LOG OUT", route: "/Profile/logout" },
      ],
      shareDialog: false,
    };
  },
  computed: {
    //   appUser() {
    //     return this.$store.state.userData;
    //   },
  },
  beforeMount() {
    // this.getLink();
  },
  methods: {
    appLogout() {
      this.$store.commit("logoutUser", true);
    },
  },
};
</script>
<style scoped>
.itempad {
  margin: 8%;
  padding: 3%;
}
</style>
  